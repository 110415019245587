import React, { useState } from "react"
import ReactPlayer from "react-player"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import VideoSlider from "../components/sliders/video-slider"
import VideoSliderSecond from "../components/sliders/video-slider-duplicate"
import BlogSlider from "../components/sliders/video-slider-three-col"
import {
  ArrowDownIcon,
  ArrowLongRightIcon,
  ArrowRightIcon,
  ClockIcon,
} from "@heroicons/react/24/solid"
import Uploader from "../components/drop-zone/uploader"
import { useForm } from "react-hook-form"
import firebase from "firebase/compat/app"
import db from "../firebaseConfig"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { PlayIcon } from "@heroicons/react/24/solid"
import CsrSlider from "../components/sliders/csr-slider"
import CavingSlider from "../components/sliders/caving-slider"

const Product = ({ data }) => {
  const images = {
    insideHeader: getImage(data.insideHeader),
    header: convertToBgImage(getImage(data.header)),

    blogOne: getImage(data.blogOne),
    blogTwo: getImage(data.blogTwo),
    blogThree: getImage(data.blogThree),
    blogFour: getImage(data.blogFour),
    blogFive: getImage(data.blogFive),
    blogSix: getImage(data.blogSix),
    blogSeven: getImage(data.blogSeven),
    blog8: getImage(data.blog8),

    gardening: getImage(data.gardening),
    grow: getImage(data.grow),
    vegetable: getImage(data.vegetable),
    watawala: getImage(data.watawala),
    leaves: getImage(data.leaves),

    //icons
    benifiteicon01: getImage(data.benifiteicon01),
    benifiteicon02: getImage(data.benifiteicon02),
    benifiteicon03: getImage(data.benifiteicon03),
    benifiteicon04: getImage(data.benifiteicon04),

    //logos
    cargeels: getImage(data.cargeels),
    daraz: getImage(data.daraz),
    glomark: getImage(data.glomark),
    keels: getImage(data.keels),
    zesta: getImage(data.zesta),

    IcedTea: convertToBgImage(getImage(data.IcedTea)),
    ginger: convertToBgImage(getImage(data.ginger)),
    tea: convertToBgImage(getImage(data.tea)),

    csrBg: convertToBgImage(getImage(data.csrBg)),
  }

  const blogs = [
    {
      image: images.blog8,
      title: "Sharing the joys of Christmas with a friendly chat",
      anchor: "/sharing-the-joys-christmas-with-friendly-chat/",
    },
    {
      image: images.blogOne,
      title: "දිරියට කෘතගුණ  Children’s Day 2022",
      anchor: "/childrens-day-22/",
    },
    {
      image: images.blogTwo,
      title: "Watawala Tea Rings in Children’s Day with Fun-Filled Event",
      anchor: "/watawala-tea-rings-in-children-day/",
    },
    {
      image: images.blogThree,
      title: "Inspiring Ethnic Harmony through Gratitude ",
      anchor: "/inspiring-ethnic-harmony-through-gratitude/",
    },
    {
      image: images.blogFour,
      title: "Watawala Scholarship Programme 2020  ",
      anchor: "/watawala-scholarship-programme-2020/",
    },
    {
      image: images.blogFive,
      title: "Watawala Tea Drinking Water Project",
      anchor: "/drinking-water-project%E2%80%93kataragama/",
    },
    {
      image: images.blogSix,
      title: "Watawala Tea Joins the Fight Against COVID-19",
      anchor: "/watawala-tea-joins-the-fight-against-covid-19/",
    },
    {
      image: images.blogSeven,
      title: "Celebrating Avurudu with the Farming Community",
      anchor: "/celebrating-avurudu-with-the-farming-community/",
    },
  ]
  const advantages = [
    {
      icon: images.benifiteicon01,
      topic: "Ginger",
      desc: "Its many anti-inflammatory compounds protect the body from free radical damage. ",
    },
    {
      icon: images.benifiteicon02,
      topic: "Coriander ",
      desc: "A natural defence against bacterial infections such as UTIs and food-borne infections. ",
    },
    {
      icon: images.benifiteicon03,
      topic: "Cinnamon",
      desc: "Its powerful antimicrobial properties can help fight off bacterial and fungal infections. ",
    },
    {
      icon: images.benifiteicon04,
      topic: "Licorice",
      desc: "Its potent antiviral effects can ease upper respiratory infections.",
    },
  ]

  const caving = [
    {
      image: images.IcedTea,
      title: "Iced Tea",
      description:
        "Cool and invigorating, iced tea can be enjoyed sweet or unsweetened.",
      link: "",
    },
    {
      image: images.ginger,
      title: "Ginger Tea",
      description:
        "Spicy but soothing, ginger tea makes for a great night cap.",
      link: "",
    },
    {
      image: images.tea,
      title: "Masala Tea      ",
      description:
        " Creamy and flavourful, masala tea makes a delicious pick-me-up. ",
      link: "",
    },
  ]

  const logo = [
    {
      image: images.daraz,
      shoplink: "https://www.daraz.lk/shop/watawala/",
    },
    {
      image: images.keels,
      shoplink: "https://www.keellssuper.com/product?cat=4&s=~WATAWALA",
    },
    {
      image: images.cargeels,
      shoplink: "https://cargillsonline.com/web/product?PS=watawala%20tea",
    },
    {
      image: images.zesta,
      shoplink: "https://www.zestaceylontea.com/product-category/watawala-tea/",
    },
    {
      image: images.glomark,
      shoplink: "https://glomark.lk/search?search-text=watawala",
    },
  ]

  return (
    <Layout>
      <Seo title="Ayur Tea | Watawala" />
      <section>
        <div>
          <div>
            <BackgroundImage
              {...images.header}
              className="w-full h-min md:h-screen  "
            >
              <div className="flex space-y-4  md:justify-between items-center h-full w-full relative z-20 container mx-auto  px-10    md:flex-row   flex-col">
               <h1 hidden>"Nourish Your Senses with the Essence of Nature: Discover the Art of Tea Leaves at Watawala Ayur Tea"</h1>
                <div className="mt-24  md:mt-24   w-full md:w-2/5 ">
                  <div className="text-4xl md:text-6xl font-semibold text-white    text-center md:text-left">
                    Ayur Tea
                  </div>
                  <div className="py-2 text-white  text-xl	 sm:text-xl  text-center md:text-left  ">
                    Packed with Ayurvedic immune-boosting herbs
                  </div>
                  <div className="py-4  flex justify-center">
                    <button className="uppercase duration-200 font-semibold text-black bg-yellow text-center rounded-full cursor-pointer py-4 px-6 group mx-auto md:ml-0">
                      <Link to="#online">
                        <div className="flex items-center">
                          <div>Buy Now</div>
                          <div className="ml-4 group-hover:ml-6 duration-300">
                            <ArrowRightIcon className="w-5" />
                          </div>
                        </div>
                      </Link>
                    </button>
                  </div>
                </div>

                <div className="w-full md:w-3/5">
                  <GatsbyImage
                    image={images.insideHeader}
                    alt="Watawala"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="h-full w-full  absolute top-0 z-10"></div>
            </BackgroundImage>
          </div>
        </div>
      </section>

      <section>
        <div className="container mx-auto md:px-20 px-10 py-20">
          <div className="flex justify-between items-center  md:flex-row   flex-col">
            <div className="md:w-1/2 w-full relative ">
              <div className="bg-brown/25   py-10 px-10  md:py-16 px-16  rounded-full relative">
                <GatsbyImage
                  image={images.watawala}
                  alt="Watawala"
                  className="w-full"
                />
                <div className="md:w-4/6 w-4/6 absolute md:-right-10  lg:-right-20 -right-2 bottom-0 ">
                  <GatsbyImage
                    image={images.leaves}
                    alt="Watawala"
                    className=""
                  />
                </div>
              </div>
            </div>

            <div className="max-w-md  pl-0   md:pl-10">
              <div className="font-semibold text-xl py-4  text-center md:text-left">
                DESCRIPTION
              </div>
              <div className="text-base			 font-normal   leading-7		  text-justify	  sm:text-left">
                Watawala Ayur Tea is an Ayurvedic blend of immune boosting herbs
                such as ginger, coriander, cinnamon and licorice. Certified by
                the Sri Lanka Department of Ayurveda, the 100% natural herbal
                tea makes for a delightful brew that can be enjoyed by the whole
                family to improve their immune system.
              </div>

              <div className="py-4 font-semibold text-xl">TASTE</div>
              <div>Earthy, Sweet, Citrusy</div>
            </div>
          </div>
        </div>
      </section>

      <section id="online">
        <div className="bg-yellow/25 py-10 ">
          <div className="container md:px-20 px-10 mx-auto py-10  ">
            <div>
              <div className="flex justify-center">
                <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                  Online shop
                </div>
              </div>
              <div className="uppercase text-3xl text-center mx-auto mb-4 tracking-widest">
                Just a click away
              </div>
              <div className="max-w-6xl text-center mb-7 mx-auto font-light text-lg">
                Now you can order your favourite Watawala teas online and have
                it delivered right to your doorstep.
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2  lg;grid-cols-5  xl:grid-cols-5  2xl:grid-cols-5 gap-8">
              {logo.map((item, index) => {
                return (
                  <div
                    className="bg-white py-6 px-4 hover:border-yellow border border-transparent duration-500 rounded-2xl flex flex-col justify-between"
                    key={index}
                  >
                    <div className="w-3/5 mx-auto  ">
                      <GatsbyImage
                        image={item.image}
                        className="w-full "
                        alt="Watawala"
                      />
                    </div>
                    <div className="flex justify-center mt-4">
                      <div className="uppercase font-light tracking-widest">
                        <div className="">
                          <a target="_blank" href={item.shoplink}>
                            <button
                              type="submit"
                              className=" tracking-wide uppercase border border-yellow bg-yellow text-black  font-semibold rounded-full px-6 py-2 hover:shadow-md duration-300 flex gap-3 items-center cursor-pointer"
                            >
                              shop now
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mx-auto   px-10 py-20">
          <div className="flex justify-between gap-20 items-center      md:flex-row   flex-col">
            <div>
              <div className="flex place-content-center	md:place-content-start">
                <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                  BENEFITS
                </div>
              </div>
              <div className="uppercase text-3xl   mb-4 tracking-widest max-w-full	md:max-w-md   text-center md:text-left">
                Infuse Overall Wellness with Every Cup of Ayur Tea
              </div>

              <div className="grid grid-cols-1 md:grid-cols-1  lg:grid-cols-2   xl:grid-cols-2    2xl:grid-cols-2  gap-10  pt-10">
                {advantages.map((item, index) => {
                  return (
                    <div className="flex gap-4 items-center">
                      <div className="bg-yellow py-8 px-8  rounded-full">
                        <div className="w-8 md:w-14">
                          <GatsbyImage image={item.icon} className="w-full" />
                        </div>
                      </div>

                      <div>
                        <div className=" font-semibold pb-2">{item.topic}</div>
                        <div>{item.desc}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="bg-blue-300/25  w-full lg:w-3/5  ">
              <StaticImage
                src="../images/product/ayur-benefits.jpg"
                className=""
                alt="AYUR TEA"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mx-auto  px-10 py-20">
          <div>
            <div className="flex justify-center">
              <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                AYURVEDA
              </div>
            </div>
            <div className="uppercase text-3xl text-center mx-auto mb-4 tracking-widest">
              Learn more about our Ayurvedic blend of natural herbs
            </div>
            <div className="max-w-6xl text-center mb-7 mx-auto font-light text-lg">
              Old or young, Watawala Ayur Tea can be enjoyed by everyone. Making
              for a delicious cup of tea, the Ayurvedic herbal infusion boosts
              immunity, helping you fight off disease and illness.
            </div>
          </div>

          <div>
            <div className="  rounded-3xl overflow-hidden   mx-auto">
              <ReactPlayer
                url="https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/ayur-herbal-video.mp4?alt=media&token=98bc7510-8b9f-4640-a4e5-e00d72a931ca"
                controls={true}
                playing={false}
                muted={true}
                width="100%"
                height="800px"
                playIcon={
                  <button className="p-8 rounded-full bg-white">
                    <PlayIcon className="w-12 text-brown" />
                  </button>
                }
                light="https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/herbal-video-thumbnail.jpg?alt=media&token=24e6cae5-eec5-4d65-9e94-235cac021555"
              />
              {/* <div className="h-full w-full bg-[#23211B]/60 absolute top-0 z-10"></div> */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-yellow/50">
          <BackgroundImage {...images.csrBg}>
            <div className="container md:px-20 px-10 mx-auto py-20 overflow-hidden">
              <div className="mb-14">
                <div className="flex justify-center">
                  <div>
                    <div className="flex justify-center">
                      <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                        watawala CSR
                      </div>
                    </div>
                    <div className="uppercase text-3xl max-w-3xl leading-normal text-center mx-auto mb-4 tracking-widest">
                      Empowering a nation, one step at a time
                    </div>
                    <div className="max-w-4xl text-center mx-auto font-light text-lg">
                      We believe in contributing towards society by supporting
                      the underprivileged in areas they need the most help.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <CsrSlider slides={blogs} />
              </div>
            </div>
          </BackgroundImage>
        </div>
      </section>
    </Layout>
  )
}

export default Product

export const query = graphql`
  query ProductpageImages {
    gardening: file(relativePath: { eq: "blog/gardening.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grow: file(relativePath: { eq: "blog/grow.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    vegetable: file(relativePath: { eq: "blog/vegetable.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    header: file(relativePath: { eq: "product/herbal-header-bg.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    insideHeader: file(
      relativePath: { eq: "product/Herbal-insideHeader.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    watawala: file(relativePath: { eq: "product/herbal-tea.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    leaves: file(relativePath: { eq: "product/herbal-leaves.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    cargeels: file(relativePath: { eq: "product/logo/cargeels.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    daraz: file(relativePath: { eq: "product/logo/daraz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    glomark: file(relativePath: { eq: "product/logo/glomark.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    keels: file(relativePath: { eq: "product/logo/keels.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    zesta: file(relativePath: { eq: "product/logo/zesta.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    benifiteicon01: file(
      relativePath: { eq: "product/herbal-icons/Ginger.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    benifiteicon02: file(
      relativePath: { eq: "product/herbal-icons/coriander.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    benifiteicon03: file(
      relativePath: { eq: "product/herbal-icons/Cinnamon.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    benifiteicon04: file(
      relativePath: { eq: "product/herbal-icons/Licorice.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogOne: file(relativePath: { eq: "pages/news/csrnews1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogTwo: file(relativePath: { eq: "pages/news/csrnews2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogThree: file(relativePath: { eq: "pages/news/csrnews3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogFour: file(relativePath: { eq: "pages/news/csrnews4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogFive: file(relativePath: { eq: "pages/news/csrnews5.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogSix: file(relativePath: { eq: "pages/news/csrnews6.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogSeven: file(relativePath: { eq: "pages/news/csrnews7.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blog8: file(relativePath: { eq: "pages/news/csrnews8.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }





    IcedTea: file(relativePath: { eq: "pages/home/IcedTea.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    ginger: file(relativePath: { eq: "pages/home/ginger.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    tea: file(relativePath: { eq: "pages/home/tea.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    csrBg: file(relativePath: { eq: "pages/home/csrBg.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
