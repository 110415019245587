import BackgroundImage from "gatsby-background-image"
import { ArrowDownIcon, ArrowLongRightIcon } from "@heroicons/react/24/solid"
import React from "react"
import { FreeMode, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"
import { Link } from "gatsby"

const CavingSlider = props => {
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={20}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          1536: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 1,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        pagination={{
          el: ".my-custom-pagination-div-caving",
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"> </span>'
          },
        }}
      >
        {props.slides.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div
                className="rounded-2xl relative h-[60vh] sm:h-[80vh]"
                key={index}
              >
                <BackgroundImage
                  {...item.image}
                  className="h-full overflow-hidden rounded-3xl"
                >
                  <div className="flex justify-end h-full">
                    <div className="p-10 flex flex-col justify-end">
                      <div className="font-semibold text-xl">{item.title}</div>
                      <div className="mt-3 mb-4 max-w-4xl font-light">
                        {item.description}
                      </div>
                      <div className="flex">
                      <Link to={item.link}>
                          <button className=" tracking-wide uppercase border border-black font-light rounded-full px-6 py-2 hover:bg-yellow hover:border-yellow duration-200">
                            learn more
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </BackgroundImage>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

export default CavingSlider
