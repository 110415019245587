import React from "react"
import ReactPlayer from "react-player"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"
import { ArrowLongRightIcon, BeakerIcon } from "@heroicons/react/24/solid"
import { FreeMode, Pagination, Autoplay } from "swiper"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const CsrSlider = props => {
  return (
    <div>
      <>
        <Swiper
          slidesPerView={3}
          spaceBetween={20}
          grabCursor={true}
          modules={[FreeMode, Pagination, Autoplay]}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 1,
            },
            0: {
              slidesPerView: 1,
            },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {props.slides.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div>
                  {/* <BackgroundImage
                    {...item.image}
                    className="rounded-[30px] h-[340px] bg-yellow/50"
                  ></BackgroundImage> */}
                 <a href={item.anchor}> <GatsbyImage
                    image={item.image}
                    alt="Watawala"
                    className="rounded-3xl  h-64 "
                  /> </a>
                  <div className="font-semibold text-2xl mt-7 mb-6 tracking-widest">
                  <a href={item.anchor}> {item.title}  </a>
                  </div>
                  <div className="uppercase font-light text-sm tracking-widest">
                    <div className="flex gap-3 tracking-wide  items-center hover:text-yellow duration-200 cursor-pointer">
                      <a href={item.anchor}>
                        <div>Learn More</div>
                      </a>
                      <div>
                        <ArrowLongRightIcon className="h-6 w-6" />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </>
    </div>
  )
}

export default CsrSlider
